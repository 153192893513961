
#not-found-content
{
    margin:$sectionPadding 0;

    .not-found-row
    {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
    }

    a.not-found-action
    {
        flex: 0 0 0;
        padding: 0 2rem;
        font-size: 1rem;
        color: $body-color;
        text-decoration: none;
        font-weight: bold;

        > svg
        {
            height: 6rem;
            margin-bottom: 1rem;
            fill: $primary;
        }
    }
}

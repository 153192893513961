#page-carousel
{
    position: relative;
    margin: 0 0 $sectionPadding;

    &.home-carousel-bar
    {
        margin-top: -2rem;
    }

    &:not(.home-carousel-bar) > .carousel-bar
    {
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    > .carousel-bar
    {
        padding: 2rem 0;
        background-color: rgba(#8ea5b6, 0.9);
        color: $colorInverse;
        font-size: 1.1rem;
        line-height: 1.5;
        max-height: 10rem;
        z-index: 10;
        position: relative;

        @include media-breakpoint-down(lg)
        {
            max-height: 12rem;
        }

        @include media-breakpoint-down(md)
        {
            p
            {
                font-size: 0.8rem;
                line-height: 1.6;
            }
        }

        @include media-breakpoint-down(sm)
        {
            position: static;

            .row
            {
                position: relative;

                > *
                {
                    position: static;
                }
            }
        }

        @include media-breakpoint-only(xs)
        {
            max-height: none;
            padding-left: 2rem;
            padding-right: 2rem;

            .row > *
            {
                padding-left: 0;
                padding-right: 0;
            }
        }

        h4
        {
            font-weight: 700;
        }
    }

    .carousel-item
    {
        text-decoration: none;
        background-color: $colorLightBlue;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;

        .row
        {
            justify-content: flex-end;
        }

        @include media-breakpoint-down(sm)
        {
            &::after
            {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(black, 0.5);
            }

            > .container
            {
                position: relative;
                z-index: 1;
            }
        }
    }

    .carousel-pillar
    {
        background-color: rgba($colorAccent, 0.9);
        color: $colorInverse;
        min-height: 23em;
        padding: 3.5rem 4rem 12rem;
        font-size: #{(5em / 4)};
        line-height: 1.8;
        display: block;

        h3
        {
            font-size: 1.625rem;
        }

        &:after
        {
            content: "\f061";
            @include line-awesome();
            display: block;
            text-align: right;
            margin-top: .75rem;
            font-size: 1.75rem;
        }

        @include media-breakpoint-down(sm)
        {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 2rem;
            background-color: transparent;
            min-height: 12rem;

            h3
            {
                text-align: right;
                border-bottom: 0;
                padding-bottom: 0;
                margin-bottom: 0;
                font-weight: 700;
                text-shadow: 0 0 3px rgba($colorBaseDark, 0.75);
                font-size: 1.6667rem;

                &:after
                {
                    content: "\f061";
                    @include line-awesome();
                    display: inline-block;
                    text-align: right;
                    font-size: 1.75rem;
                    margin-top: 0;
                    line-height: 1.2;
                    text-shadow: 0 0 3px rgba($colorBaseDark, 0.75);
                    margin-left: .5rem;
                }
            }

            p
            {
                display: none;
            }

            &:after
            {
                display: none;
            }
        }
    }

    .carousel-indicators
    {
        margin: 0 4.8rem;
        bottom: 11rem;
        right: auto;
        justify-content: flex-start;

        @include media-breakpoint-down(lg)
        {
            bottom: 11.5rem;
        }

        @include media-breakpoint-down(sm)
        {
            bottom: calc(100% + 3rem);
            margin: 0;
        }

        @include media-breakpoint-only(sm)
        {
            left: 15px;
        }

        li
        {
            width: .75rem;
            height: .75rem;
            background-color: transparent;
            border: 1px solid $colorInverse;
            opacity: 1;

            &.active
            {
                background-color: $colorBlue;
            }
        }
    }

    .carousel-search
    {
        @extend .px-md-5;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include media-breakpoint-only(xs)
        {
            margin-top: 1rem;
        }
    }
}

.home-content-header.content-header
{
    background-position: 66.667% center;

    @include media-breakpoint-only(xs)
    {
        background-size: auto 115%;
    }
}

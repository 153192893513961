a.social-button
{
    display: inline-flex;
    padding: 0.25em;
    width: 2em;
    height: 2em;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: $colorGrey;
    color: $colorLightGrey;
    text-shadow: 0 1px 3px rgba($colorBaseDark, 0.2);
    transition: background-color ease-in-out 0.25s;
    margin-right: .25em;
    text-decoration: none;

    &:last-child
    {
        margin-right: 0;
    }

    &:hover
    {
        background-color: $colorLightBlue;
    }

    img
    {
        max-width: 100%;
    }
}

.card
{
    width: 100%;
    border: 0;

    > .card-header
    {
        background-color: transparent;
        border-top: 8px solid currentColor;
        border-bottom: 0;
        padding-bottom: 0;

        > h3
        {
            font-size: 2rem;
            font-weight: 700;
            padding-bottom: 1rem;
            border-bottom: 3px solid currentColor;
            margin-bottom: 0;
            line-height: 0.8;

            > small
            {
                display: block;
                font-size: 1.5rem;
            }

            @include media-breakpoint-down(sm)
            {
                font-size: 1.3333rem;

                > small
                {
                    font-size: 1.1rem;
                }
            }
        }
    }

    &.page-siblings,
    &.page-office
    {
        > .card-header
        {
            > h3
            {
                font-size: 1.5rem;
                font-weight: 100;

                > strong
                {
                    font-size: 2rem;
                    font-weight: 700;
                    display: block;
                }

                @include media-breakpoint-down(lg)
                {
                    font-size: 1.333rem;

                    > strong
                    {
                        font-size: 1.9rem;
                    }
                }

                @include media-breakpoint-down(md)
                {
                    > strong
                    {
                        font-size: 1.75rem;
                    }
                }
            }
        }
    }

    > .card-twitter
    {
        max-height: 390px;
        overflow: auto;
    }

    &.bg-secondary
    {
        > .card-header
        {
            border-top-color: rgba(white, 0.4);

            > h3
            {
                border-bottom-color: rgba(white, 0.4);
            }
        }
    }

    .card-footer-link
    {
        text-transform: uppercase;
        font-size: 0.85em;
        font-weight: bold;

        i
        {
            font-size: 1.5em;
            vertical-align: -3px;
        }
    }
}

.card-nav
{
    display: flex;
    flex-direction: column;

    > a
    {
        text-decoration: none;
        color: $colorAlternate;
        padding: .5em 0;
        line-height: 1.2;

        &:hover
        {
            color: $colorLightBlue;
        }

        > i
        {
            color: $colorAccent;
        }
    }

    &.collapse,
    &.collapsing
    {
        margin-left: 1rem;
    }
}

aside.card
{
    margin: 0;

    @include media-breakpoint-down(sm)
    {
        .card-header > h3
        {
            font-size: 1.25rem;
        }
    }

    > .card-body
    {
        @include media-breakpoint-only(xs)
        {
            padding: 1.5rem;
        }
    }

    &.bg-dark, &.bg-secondary, &.page-callback
    {
        .card-header
        {
            border-top-color: rgba(white, 0.5);

            > h3
            {
                border-bottom-color: rgba(white, 0.5);
            }
        }

        .card-nav > a
        {
            color: $colorInverse;

            &:hover
            {
                color: $colorLightBlue;
            }
        }
    }

    &.page-siblings
    {
        .card-body
        {
            padding-top: 1rem;
        }
    }

    &.office-opening,
    &.page-office,
    &.page-siblings
    {
        @include media-breakpoint-down(lg)
        {
            max-width: 30rem;
            margin: 0 auto;
        }
    }
}

section.card
{
    margin: 0;

    > .card-body
    {
        padding: 4rem;

        @include media-breakpoint-down(lg)
        {
            padding: 3rem;
        }

        @include media-breakpoint-only(xs)
        {
            padding: 1.5rem;
        }
    }
}

section.page-callback
{
    > .card-body
    {
        padding: 4rem 3.5rem;
        display: flex;
        justify-content: center;
        flex-direction: column;

        @include media-breakpoint-down(lg)
        {
            padding: 3rem 2.5rem;
        }

        @include media-breakpoint-only(xs)
        {
            padding: 1.5rem;
        }
    }

    @include media-breakpoint-down(md)
    {
        max-width: 30rem;
        margin: 0 auto;
    }
}

.page-callback
{
    h4
    {
        font-size: 1.125rem;
        margin-top: 1.75rem;

        &:first-child
        {
            margin-top: 0;
        }
    }
}

section.card-contact
{
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.card-overlap
{
    @extend .clearfix;
    position: relative;

    @include media-breakpoint-up(lg)
    {
        > .card
        {
            &:first-child
            {
                width: 57%;
                float: left;
                margin-right: -2%;
                z-index: 1;
            }

            &:last-child
            {
                margin-top: 5rem;
                width: 47%;
                margin-left: -2%;
                padding-left: 4%;
                float: right;
            }

            .card-body
            {
                overflow: auto;
            }
        }
    }
}

.alternate-search-link
{
    @include uppercaseText();
    color: $colorInverse;
    text-decoration: none;
    font-family: $altFont;
    font-size: .75rem;
    font-weight: bold;
    letter-spacing: 1px;
}

$mainFont: 'myriad-pro', sans-serif;
$altFont: 'museo-sans', sans-serif;

$colorRed: #ab1832;
$colorBlue: #35474f;
$colorLightBlue: #9d9d9c;
$colorGrey: #9d9d9c;
$colorLightGrey: #f8f8f8;
$colorDarkGrey: #666;

$colorInverse: $colorLightGrey;
$colorBaseLight: $colorGrey;
$colorBaseMid: $colorDarkGrey;
$colorBaseDark: black;
$colorAccent: $colorRed;
$colorAlternate: $colorBlue;

$textSizeBase: 16px;
$baseShadow: 0 0 5px 0.5px rgba(black, 0.2);
$borderSize: 3px;
$altBorderSize: 2px;

$sectionPadding: 4rem;

/* Bootstrap overrides */
$line-height-base: 2;

@mixin uppercaseText()
{
    text-transform: uppercase;
}

@mixin arrowButton()
{
    display: inline-block;
    background-color: $colorAccent;
    color: $colorInverse;
    font-size: .75rem;
    font-style: normal;
    border: 0;
    padding: 1rem 4.5rem 1rem 1.5rem;
    line-height: 1;
    position: relative;
    text-decoration: none;
    @include uppercaseText();
    letter-spacing: 1px;
    font-weight: bold;
    font-family: $altFont;
    max-width: 100%;

    &:after
    {
        content: "\f30b";
        position: absolute;
        top: 0;
        right: 0;
        width: 3rem;
        height: 100%;
        display: block;
        @include line-awesome();
        background-color: $colorLightBlue;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        font-size: 1.5rem;
        /*
        content: "\f061";
        display: block;
        padding: 1em;
        background-color: $colorAlternate;
        position: absolute;
        right: 0;
        top: 0;
        box-sizing: content-box;*/
    }
}

@function encodecolor($string)
{
    @if type-of($string) == 'color'
    {
        $hex: str-slice(ie-hex-str($string), 4);
        $string: unquote("#{$hex}");
    }

    $string: '%23' + $string;
    @return $string;
}

@mixin line-awesome()
{
    font-family: 'Line Awesome Free';
    font-weight: bold;
}

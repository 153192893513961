.page-siblings.card
{
    border: 0;
    /* position: sticky;
    top: 1rem; */
    @include uppercaseText();
    letter-spacing: 1px;

    .card-header
    {
        color: $colorAlternate;
    }

    .card-body > .card-nav
    {
        a
        {
            padding-left: .5em;
            padding-right: .5em;
        }

        > a
        {
            color: $colorAccent;
            font-weight: bold;
            font-family: $altFont;
            font-size: 1.25rem;
        }

        > a + nav.card-nav
        {
            margin-left: 1rem;
        }

        > nav.card-nav
        {
            a
            {
                font-size: .9375rem;
                color: $colorGrey;

                &.active, &:hover
                {
                    font-weight: 600;
                    color: $colorLightBlue;

                    &:after
                    {
                        content: "\f061";
                        color: $colorAccent;
                        @include line-awesome();
                        display: inline-block;
                        margin-left: .5rem;
                    }
                }
            }
        }
    }
}

.btn-secondary
{
    @include button-variant($secondary, $colorGrey);
}

.text-white a
{
    color: $colorInverse;
}

p + h3
{
    margin-top: 2rem;
}

img.lazyload
{
    width: 100%;
    max-width: 100%;
    display: block;
}

.text-light .table
{
    color: $light;
}

.input-group-prepend > .input-group-text
{
    border-right-color: $white;
    z-index: 0;
}

.input-group-append > .input-group-text
{
    border-left-color: $white;
    z-index: 0;
}

.input-group button
{
    border-top-color: $input-border-color;
    border-right-color: $input-border-color;
    border-bottom-color: $input-border-color;
    background-color: $white;
    z-index: 0;
}

.breadcrumb-item + .breadcrumb-item::before
{
    display: block;
    float: left;
}

.link-underline a
{
    text-decoration: underline;
}
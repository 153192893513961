@mixin generic-dropdown
{
    @include border-radius(0);
    z-index: $zindex-dropdown;
    background-color: $colorInverse;
    width: max-content;
    max-width: calc(100vw - 3rem);
    border: 0;
    box-shadow: 0 0 .25rem rgba(black, 0.5);
    padding-top: 1rem;
    padding-bottom: 1.5rem;

    > .nav-item
    {
        > .nav-link
        {
            border-left: .25rem solid transparent;
            border-right: .25rem solid transparent;
            padding: .5rem 2.5rem;
            margin: .25em 0;
            font-size: .875rem;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 1.5;
            color: $colorAlternate;

            > strong
            {
                font-weight: 600;
            }
        }

        &.active > .nav-link,
        &:hover > .nav-link
        {
            border-left-color: $colorAccent;
        }
    }
}

@mixin main-dropdown
{
    @include border-radius(0);
    background-color: $colorAlternate;
    z-index: $zindex-dropdown;
    font-family: $mainFont;
    width: max-content;
    max-width: calc(100vw - 3rem);
    border: 0;
    box-shadow: 0 0 .25rem rgba(black, 0.5);
    padding-top: 1rem;
    padding-bottom: 1.5rem;

    > .nav-item
    {
        > .nav-link
        {
            border-left: .25rem solid transparent;
            border-right: .25rem solid transparent;
            padding: .5rem 2.5rem;
            margin: .25em 0;
            font-size: .875rem;
            font-weight: 600;
            letter-spacing: 0;
            line-height: 1.5;

            > strong
            {
                font-weight: 600;
            }
        }

        &.active > .nav-link,
        &:hover > .nav-link
        {
            border-left-color: $colorAccent;
        }
    }
}

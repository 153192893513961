div[map-element]
{
    min-height: 36em;

    .gmaps-info-window
    {
        padding: 1rem;

        h4
        {
            font-size: 1rem;
        }

        p
        {
            line-height: 1.5;
            font-size: .9rem;
        }
    }
}

div[map-search]
{
    .input-group
    {
        > button.btn.btn-dark
        {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-left: 0;
            background-color: white;
            color: $colorBaseMid;
        }
    }
}


div[map-directions]
{
    margin: 1rem 0;

    .adp
    {
        table
        {
            width: 100%;
        }
    }

    .adp-placemark td
    {
        padding: 6px 12px;
    }
}

@import "../variables";

$primary: $colorRed;
$secondary: $colorBlue;

$body-color: $colorBlue;

$font-weight-normal: normal;
$font-family-sans-serif: $mainFont;
$headings-font-family: $altFont;
$headings-font-weight: 700;
$small-font-size: #{(2rem / 3)};

$input-btn-font-size: 0.875rem;
$input-group-addon-bg: #fff;

$card-spacer-y: 2rem;
$card-spacer-x: 2rem;
$card-border-radius: 0;

$grid-breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1340px );
$container-max-widths: ( sm: 540px, md: 720px, lg: 960px, xl: 1300px );

$nav-tabs-border-radius: 0;
$nav-tabs-link-active-bg: $colorLightBlue;
$nav-tabs-link-active-color: $colorInverse;

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";
.content-header
{
    background-color: $colorAccent;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin: 0 0 2rem;
    position: relative;
    color: $colorInverse;
    font-family: $altFont;

    &[style*=contain]
    {
        background-color: #fff;
    }

    &:not(.home-content-header)::after
    {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-image: linear-gradient(-30deg, rgba(black, 0.7), transparent 80%);

        @include media-breakpoint-down(sm)
        {
            background-image: none;
            background-color: rgba(black, 0.5);
        }
    }

    > .container
    {
        position: relative;
        min-height: 24rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: flex-end;
        z-index: 2;

        @include media-breakpoint-down(md)
        {
            min-height: 20rem;
        }

        @include media-breakpoint-down(sm)
        {
            min-height: 12rem;
        }
        /* IE11 HACK */
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
        {
            height: 24rem;
        }
    }

    &.content-header-content
    {
        > .container
        {
            @include media-breakpoint-down(sm)
            {
                min-height: 18rem;
            }
        }
    }

    h1
    {
        font-size: 2.5rem;
        font-weight: 700;
        text-shadow: 0 0 3px rgba($colorBaseDark, 0.75);

        @include media-breakpoint-down(md)
        {
            font-size: 2.2rem;
        }

        @include media-breakpoint-down(sm)
        {
            font-size: 1.6667rem;
        }
    }

    h2
    {
        font-size: 1.8rem;
        margin-top: -.7rem;
        text-shadow: 0 0 3px rgba($colorBaseDark, 0.75);

        @include media-breakpoint-down(sm)
        {
            font-size: 1.1rem;
            margin-top: -.5rem;
        }
    }

    a
    {
        color: $colorInverse;
    }

    .content-header-body
    {
        font-weight: 700;
        font-size: .875rem;
        line-height: 1.5;
        letter-spacing: 1px;

        @include uppercaseText();

        a[href^=mailto]
        {
            text-transform: none;
        }

        i
        {
            margin-left: .3333rem;
            margin-top: .16667rem;
            float: right;
        }

        @include media-breakpoint-down(sm)
        {
            text-align: right;
        }
    }

    .content-header-footer
    {
        position: absolute;
        bottom: 0;
        left: #{($grid-gutter-width / 2)};
        right: #{($grid-gutter-width / 2)};
        justify-content: flex-end;
        font-weight: 700;
        letter-spacing: 1px;
        @include uppercaseText();

        .breadcrumb
        {
            display: inline-flex;
            margin-bottom: 0;
            border-radius: 0;
            z-index: 2;
            padding: .75rem 2rem;
            background-color: white;
            font-size: .875rem;

            a
            {
                color: $colorAccent;
            }

            .breadcrumb-item + .breadcrumb-item::before
            {
                padding-left: .5rem;
                padding-right: 1rem;
            }
        }

        .content-header-name
        {
            background-color: $colorAlternate;
            color: $colorInverse;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            line-height: 1.4;
            flex: 0 0 calc(25% - #{($grid-gutter-width / 1.333)});
            max-width: calc(25% - #{($grid-gutter-width / 1.333)});

            @include media-breakpoint-down(md)
            {
                flex: 0 0 calc(33% - #{($grid-gutter-width / 1.333)});
                max-width: calc(33% - #{($grid-gutter-width / 1.333)});
            }
        }

        @include media-breakpoint-down(sm)
        {
            flex-direction: column;

            .breadcrumb
            {
                justify-content: center;
            }

            .content-header-name
            {
                max-width: none;
            }
        }
    }
}

.col-img
{
    > img
    {
        max-width: 100%;
    }

    @include media-breakpoint-down(md)
    {
        max-width: 20rem;
        margin: 0 auto;
    }
}

.content-heading-button-group
{
    margin-bottom: .5rem;

    > h1
    {
        display: inline;
    }

    > a.news-read-more
    {
        margin-left: 1rem;
        vertical-align: .6667rem;
    }

    @include media-breakpoint-down(sm)
    {
        margin-top: 1rem;

        > a.news-read-more
        {
            display: block;
            margin: 0 0 1rem;
        }
    }
}

button.btn-submit,
a.btn-submit
{
    @extend .btn-primary, .btn-block;
    color: $colorInverse;
    border-width: $altBorderSize;
    color: $colorInverse;
    border-width: $altBorderSize;
    font-size: 0.8em;
    font-weight: 700;
    font-family: $altFont;
    letter-spacing: 1px;
    @include uppercaseText();
}

label
{
    text-transform: none;
    letter-spacing: 0;
    font-weight: 500;
}

.btn.btn-dropdown
{
    text-align: left;
    background-color: white;
    border-color: rgba($colorAlternate, 0.1);

    &::after
    {
        float: right;
        margin-top: .75rem;
    }
}

.custom-control .custom-control-label
{
    &::before,
    &::after
    {
        top: 50%;
        transform: translateY(-50%);
    }
}

.control-label-marketing
{
    padding-right: 1.5rem;
    position: relative;

    > .marketing-prompt
    {
        font-size: 1.25rem;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

#form-marketing
{
    margin-top: 1rem;
    font-size: .85rem;
    line-height: 1.75;
    text-align: justify;

    a
    {
        color: $colorAlternate;
    }
}


.field-validation-error
{
    background-color: $warning;
    display: inline-block;
    padding: .4em .5em;
    font-size: 75%;
    font-weight: bold;
    line-height: 1;
    vertical-align: baseline;
    border-radius: 0.25rem;
    color: $colorBaseDark;
    margin: .333rem 0;
}
.banner
{
    background-color: $colorRed;
    color: #fff;
    text-align: center;
    padding: 1rem;
    text-shadow: 0 0 .125em #000;

    > h4
    {
        font-size: 1rem;
        line-height: 1.5;
        margin: 0;

        @media (max-width: 24.5rem)
        {
            max-width: 18rem;
            margin: 0 auto;
        }
    }

    > p
    {
        line-height: 1.5;
        margin-bottom: 0;
        font-size: .875rem;

        @media (max-width: 25.5rem)
        {
            max-width: 20rem;
            margin: 0 auto;
        }
    }

    a
    {
        color: #fff;
    }
}

h1,
h2,
h3,
h4,
h5,
h6
{
    @include uppercaseText();
    letter-spacing: 2px;
}

h3,
h4,
h5,
h6
{
    letter-spacing: 1px;
}

h3
{
    padding-bottom: .5rem;
    margin-bottom: 1.3rem;
    border-bottom: $borderSize solid rgba($colorLightGrey, 0.3);

    @include media-breakpoint-down(sm)
    {
        font-size: 1.333rem;
    }
}

h4
{
    font-weight: bold;
}

main
{
    section, aside
    {
        margin: $sectionPadding 0;

        @include media-breakpoint-only(xs)
        {
            margin: #{($sectionPadding / 2)} 0;
        }
    }
}

summary
{
    display: block;

    &::-webkit-details-marker
    {
        display: none;
    }
}
p
{
    font-size: 1.1rem;
    line-height: 2rem;
    margin-bottom: 1.2rem;

    &:last-child
    {
        margin-bottom: 0;
    }
}

.font-alternate
{
    font-family: $altFont;
}
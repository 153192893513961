header#page-header
{
    padding: #{$sectionPadding / 2} 0;
    background-color: $colorBlue;
    color: $colorInverse;

    a
    {
        color: $colorInverse;
    }
}

div.header-social
{
    text-align: right;
    font-size: 1.2em;
    display: flex;
    flex-wrap: wrap;
    min-height: 4em;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-down(md)
    {
        justify-content: space-around;
    }

    .review-banners
    {
        display: grid;
        grid-template-areas: "a a" "b c";
        grid-gap: .667rem;

        > p
        {
            text-align: center;
            margin: 0;
            text-transform: uppercase;
            font-size: .8rem;
            font-family: $altFont;
            font-weight: normal;
            letter-spacing: 1px;
            line-height: 1.25em;
            grid-area: a;
        }
    }

    .social-button
    {
        margin-left: .333rem;
        margin-right: .333rem;
    }

    .social-icons
    {
        display: inline-flex;
        justify-content: center;

        @include media-breakpoint-down(md)
        {
            flex-grow: 1;
            margin-top: 1rem;
        }
    }
}

@import "_nav";


@include media-breakpoint-down(sm)
{
    body
    {
        padding-top: 3rem;
    }
}
@import "inc/variables";
@import "inc/bootstrap/critical";

@import "inc/editor";
@import "inc/js-helpers";
@import "inc/overrides";
@import "inc/ckstyles";

@import "parts/forms";
@import "parts/cards";

@import "parts/skiplinks";
@import "parts/header";
@import "parts/social";
@import "parts/carousel";
@import "parts/nav-siblings";
@import "parts/page-content";
@import "parts/callback";
@import "parts/banner";
@import "parts/christmas-banner";
@import "parts/errors";
@import "parts/maps";


@include media-breakpoint-down(sm)
{
    html
    {
        font-size: 13px;
    }

    p {
        line-height: 1.667rem;
    }
}

img.site-logo
{
    display: block;
    max-width: 18rem;
    max-height: 9rem;

    @include media-breakpoint-down(sm)
    {
        max-height: 8.5rem;
        margin: 0 auto 2rem;
    }
}

@import "_dropdowns";

nav#page-nav,
nav#header-nav-alt
{
    .navbar-nav
    {
        justify-content: space-around;
        width: 100%;

        > .nav-item
        {
            > .dropdown-menu
            {
                @include main-dropdown;
                top: calc(100% + #{$borderSize});

                .dropdown-menu
                {
                    @include generic-dropdown;
                    top: 0;
                    left: 100%;

                    @include media-breakpoint-up(lg)
                    {
                        .dropdown-menu
                        {
                            position: static;
                            border: 0;
                            padding: 0 0 0 1rem;
                            width: 100%;
                            box-shadow: none;
                        }
                    }
                }
            }
        }
    }

    .dropdown
    {
        > a:after
        {
            content: "\f107";
            @include line-awesome();
            margin-left: .5em;
        }

        &.show > a:after
        {
            content: "\f106";
        }
    }

    .dropdown-menu .dropdown
    {
        > a:after
        {
            content: "\f105";
        }

        &.show > a:after
        {
            content: "\f104";
        }
    }
}

nav#page-nav
{
    margin-top: #{$sectionPadding / 2};
    padding: 0;
    font-family: $altFont;


    @include media-breakpoint-up(lg)
    {
        border-top: $borderSize solid $colorGrey;
        border-bottom: $borderSize solid $colorGrey;
    }

    @include media-breakpoint-down(lg)
    {
        font-size: .9rem;
        text-align: center;
        margin-top: 1rem;
    }

    @include media-breakpoint-down(md)
    {
        margin-top: 2rem;
    }

    @include media-breakpoint-down(sm)
    {
        margin-top: 0;
    }

    .navbar-toggler
    {
        @include uppercaseText();
        font-weight: 100;
        color: $colorInverse;
        letter-spacing: 1px;
        font-weight: bold;
        border: 0;
        outline: 0;

        .navbar-toggler-icon
        {
            float: right;
            margin-left: 1rem;
            margin-top: -.3333rem;
        }

        &:not(.collapsed)
        {
            text-indent: 1000vw;
            overflow: hidden;

            .navbar-toggler-icon
            {
                background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M18.717%206.697l-1.414-1.414L12%2010.586%206.697%205.283%205.283%206.697%2010.586%2012l-5.303%205.303%201.414%201.414L12%2013.414l5.303%205.303%201.414-1.414L13.414%2012z%22%20fill%3D%22rgba%28255%2C255%2C255%2C0.5%29%22%2F%3E%3C%2Fsvg%3E");
            }
        }
    }

    .navbar-nav
    {
        @include media-breakpoint-down(md)
        {
            text-align: right;
            padding: 0 1rem;
        }

        > .nav-item
        {
            .nav-link
            {
                @include uppercaseText();
                font-weight: 100;
                color: $colorInverse;
                letter-spacing: 1px;

                @include media-breakpoint-down(md)
                {
                    line-height: 1.6;
                    padding-top: .5rem;
                    padding-bottom: .5rem;
                }

                > strong
                {
                    font-weight: bold;
                }
            }

            @include media-breakpoint-up(lg)
            {
                margin: -$borderSize .5rem;
                border-top: $borderSize solid $colorGrey;
                border-bottom: $borderSize solid $colorGrey;

                &:first-child
                {
                    margin-left: 0;
                }

                &:last-child
                {
                    margin-right: 0;
                }

                &.active,
                &:hover,
                &.show
                {
                    border-top-color: $colorLightGrey;
                    border-bottom-color: $colorLightGrey;
                }
            }

            @include media-breakpoint-down(md)
            {
                .dropdown-menu
                {
                    background-color: transparent;
                    box-shadow: none;
                    text-align: right;
                    width: 100%;
                    padding-top: .25rem;
                    padding-bottom: .25rem;

                    .dropdown-menu
                    {
                        background-color: transparent;
                        box-shadow: none;
                        text-align: right;
                        width: 100%;
                        padding-right: 1.5rem;
                        padding-top: .25rem;
                        padding-bottom: .25rem;
                    }

                    .nav-item > .nav-link,
                    .dropdown-menu > .nav-item > .nav-link
                    {
                        color: $colorInverse;
                        padding: .25rem .5rem;
                    }

                    .nav-item > .nav-link:hover,
                    .dropdown-menu > .nav-item > .nav-link:hover,
                    .nav-item:hover > .nav-link,
                    .dropdown-menu > .nav-item:hover > .nav-link
                    {
                        border-left-color: transparent;
                        border-right-color: $colorAccent;
                    }

                    .dropdown
                    {
                        > a:after
                        {
                            content: "\f107";
                        }

                        &.show > a:after
                        {
                            content: "\f106";
                        }
                    }
                }
            }
        }
    }

    .bg-primary .navbar-nav
    {
        padding-top: .75rem;
        padding-bottom: .75rem;
        margin-top: .75rem;
    }
}

nav#header-nav-alt
{
    font-size: .8125em;
    margin-bottom: 1rem;
    font-family: $altFont;
    padding-left: 2rem;
    padding-right: 2rem;

    .navbar-nav > .nav-item .nav-link
    {
        @include uppercaseText();
        font-weight: 700;
    }
}

.navbar-collapse .header-social
{
    margin-top: 1.5rem;
}

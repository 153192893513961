.page-callback
{
    @extend .bg-primary, .text-white;

    .form-control, .custom-select, .custom-file-label, .input-group-text
    {
        border-color: rgba($colorAlternate, 0.1);
        background-color: rgba($colorInverse, 0.1);
        color: $colorInverse;

        &:active,
        &:focus
        {
            border-color: $colorInverse;
        }

        > option
        {
            color: $colorBaseDark;
        }
    }

    .custom-file-label
    {
        font-size: 0.875rem;

        &::after
        {
            text-transform: uppercase;
            color: $colorAccent;
            font-weight: 600;
            letter-spacing: 1px;
        }
    }

    .form-control::placeholder
    {
        color: $colorInverse;
    }

    textarea.form-control::placeholder
    {
        display: block;
        line-height: 1.14;
        padding: 2px 0;
        overflow: hidden;
    }

    .custom-select
    {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='#{encodecolor($colorInverse)}' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e");

        > option
        {
            color: $colorAlternate;
        }
    }

    .custom-checkbox
    {
        font-size: 0.8em;
        line-height: 1.4;

        .custom-control-label::before
        {
            background-color: $colorAccent;
            border-color: $colorInverse !important;
            border-radius: 50%;
        }
    }

    fieldset > legend
    {
        @include uppercaseText();
        font-weight: bold;
        letter-spacing: 1px;
        font-size: 1.25rem;
        font-family: $altFont;

        @include media-breakpoint-down(sm)
        {
            font-size: 1.1rem;
        }
    }

    &.page-payment > .card-body
    {
        padding: 2rem;
    }
}
